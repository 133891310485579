import { navigate } from "gatsby";
import React, { useEffect } from "react";

import SEO from "../components/seo";

const Home = () => {
  useEffect(() => {
    navigate("/work");
  }, []);
  return (
    <>
      <SEO />
    </>
  );
};

export default Home;
